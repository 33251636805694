import Main from '@Marketing/Main'
import Home from '@Marketing/Home'

const Index = ({
  // posts,
  // title,
  description,
  // ...props
}) => {
  return (
    <Main pageTitle='missionbase.com' description={description}>
      <Home description={description} />
    </Main>
  )
}

export default Index
